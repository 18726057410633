import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo2.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="footer_row">

        <div className='footer_coll' >
          
          <p>Great platform for the job seeker that passionate about startups. Find your dream job easier.</p>
          <br />
            
        </div>
        <div className='footer_coll' >
          <a href=""></a>
          <a href=""></a>
          <a></a>s
        </div>
        <div className='footer_coll'  >
        <h2>Get notifications on Email</h2>
        
        <p>The latest notifications news, articles, sent to your inbox weekly.</p>
        
        </div>

 
        </div>
        
        </div>

      </section> 
    </>
  )
}
export default Footer;