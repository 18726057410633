import React, { useEffect } from 'react'
import {motion} from "framer-motion"


import '../components/Styles/home.css'

import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import hpic from '../images/hpic1-min.png'
import hpic2 from '../images/hpic2.png'
import ic1 from '../images/ic1.png';
import ic2 from '../images/ic2.png';
import ic3 from '../images/ic3.png';
import ic4 from '../images/ic4.png';
import ic5 from '../images/ic5.png';
import ic6 from '../images/ic6.png';
import ic7 from '../images/ic7.png';
import ic8 from '../images/ic8.png';

 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Hiring Gate</title>
      <meta name="description" content="Discover and hire the best remote IT professionals effortlessly. Connect with skilled candidates across the USA."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp'  >
      <br /><br />
        <div className='rowwww'>
         <div className='h_col'>
        <h1>Your Gateway to Top IT Talent </h1>
<br />
  <img src={hpic} alt="paint"  />
  <br /><br />
     <h2>Discover and hire the best remote IT professionals effortlessly. Connect with skilled candidates across the USA.</h2>
   <br />
     <button><a href="contact">Get Started</a> </button>
         </div>
         <div className='h_col2a' >
             <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>


     <section className=''>
        <div className='home__div'>
             <h2>Looking for a Resource In?</h2>
           <div className='home__row'>
             <div className='home__col'>
             <img src={ic6} alt="" />
              <h2>Design</h2>
              <p>235 available</p>
  
             </div>
             <div className='home__col'>
             <img src={ic1} alt="" />
              <h2>Sales</h2>
              <p>235 available</p>
  
             </div>
             <div className='home__col'>
             <img src={ic2} alt="" />
              <h2>Marketing</h2>
              <p>235 available</p>
   
             </div>
 
             <div className='home__col'>
             <img src={ic7} alt="" />
              <h2>Finance</h2>
              <p>235 available</p>
  
             </div>
 
           </div>

<br />
<div className='home__row'>
             <div className='home__col'>
             <img src={ic3} alt="" />
              <h2>Technology</h2>
              <p>235 available</p>
    
             </div>
             <div className='home__col'>
             <img src={ic4} alt="" />
              <h2>Engineering</h2>
              <p>235 available</p>
  
             </div>
             <div className='home__col'>
             <img src={ic5} alt="" />
              <h2>Business</h2>
              <p>235 available</p>
  
   
             </div>
 
             <div className='home__col'>
             <img src={ic8} alt="" />
              <h2>Human Resource</h2>
              <p>235 available</p>
  
             </div>
 
           </div>

        </div>
     </section>
     <br />
     <section>
      <div className='home__div'>
         <h2>How it Works?</h2>
         <div id='divh'><h3>Apply</h3></div>
         
      </div>

     </section>
<br />



    </div>
  )
}
export default Home;