import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import hpic from '../images/hpic1-min.png'
import hpic2 from '../images/hpic2.png'
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';


 const Contact =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>WealthPath Conference 2024: Empowering Your Financial Future</title>
      <meta name="description" content="Empower your financial journey at WealthPath Conference 2024. Gain insights into investing, wealth management, and financial planning from leading experts."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp'  >
      <br /><br />
        <div className='h_row'>
         <div className='h_col'>
            <br /><br /><br />
        <h1>Contact Us</h1>
<br />
  <img src={hpic} alt="paint" />
  <br /><br />
     <h2>Get in Touch with Us Today. Let's Connect and Build Your Dream Team. 

</h2>
   <br />

         </div>
         <div className='h_col2a'>
             <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>


<section className=''>
<div className='con_sec'>
<div className='con_row'>
    <div className='con_col'>
        <h2>Reach Out to Us </h2>
        <p>Fill out the form below, and our team will get back to you promptly to discuss your hiring needs or job opportunities. </p>
        <br />
<a href="">1055 Arthur ave Elk Groot, 67. <br />
New Palmas South Carolina.</a>
<br />
<a href="">+1 234 678 9108 99</a>
<br />
<a href="">info@hiringgate.com</a>
<br /><br />
<h2>Let us help</h2>
<p>✓ Find top talent. </p>
<p>✓ Discover remote jobs. </p>
<p>✓ Answer your questions. </p>
<p>✓ Provide support.  </p> 
    </div>
    <div className='con_col'>
        <div className='for_m'>
         <div className='for_m1'>
           
           <div className='for_row'>
            <div className='for_col' >
                <input type="text"  id='colinpp'/>
            </div>
            <div className='for_col'>
                <input type="text" />
            </div>
           </div>
           <br />
           <input type="text" />
           <br /><br />
           <textarea id="" name="" rows="4" cols="50">

           </textarea>
           <br /><br />
           <button>Send Message</button>
         </div>
         <br />
        </div>
    </div>
</div>
</div>
</section>
<br /><br />

 <section className='sec__last_1'>
  <div className='sec__last1m'>
  <h2>Start Hiring with us today</h2>
  <p>Imagine scaling without the headache.</p>
  <button>Get Started</button>
  </div>
 </section>
<br /><br />
 <section className=''>
  <div className='lst_sec'>
   <h2>What Our Clients Say…</h2>
   <br /><br />
   <div className='lst_row'>
    
    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>
   </div>


  </div>
 </section>
    </div>
  )
}
export default Contact;