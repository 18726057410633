import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import hpic from '../images/hpic1-min.png'
import logca1 from '../images/Group 3919-min.png'
import logca2 from '../images/Group 3920-min.png'
import logca3 from '../images/Group 3922-min.png'
import logca4 from '../images/Group 3923-min.png'
import logca5 from '../images/Group 3924-min.png'
import logca6 from '../images/Group 3925-min.png'
import hpic2 from '../images/hpic2.png'
import lg1 from '../images/complg1.png'
import '../components/Styles/home.css'
import '../components/Styles/career.css'
import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Careers =()=> {
  useEffect(()=>{
    AOS.init();

})
  return (
    <div>
      <Helmet>
      
      <title>WealthPath Conference 2024: Empowering Your Financial Future</title>
      <meta name="description" content="Empower your financial journey at WealthPath Conference 2024. Gain insights into investing, wealth management, and financial planning from leading experts."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp'  >
      <br /><br />
        <div className='h_row'>
         <div className='h_col'>
            <br /><br /><br />
        <h1>Find your Dream Job</h1>
<br />
  <img src={hpic} alt="paint" />
  <br /><br />
     <h2>Our virtual assistants are pre-trained and ready to support your digital  marketing needs. Trusted by leading agencies, they’re ready to  contribute to your success.</h2>
   <br />

         </div>
         <div className='h_col2a'>
             <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>


<section>
   <div className='career_mid'>
<h2>Our Hiring Process</h2>
<br />
<br />
<div className='rooww'>
<div className='col1aa'>
  <img src={logca1} alt="" />
</div>
<div className='col2aa'>
   <h2>The Resume Hunt!</h2>
   <hr style={{color:"blue"}}/>
   <p>Once we spot your golden CV, our HR Associate will dial you up for a screening session.</p>
</div>
</div>
<br />
<div className='rooww'>
<div className='col1aa'>
  <img src={logca2} alt="" />
</div>
<div className='col2aa'>
   <h2>Initial Screening Call</h2>
   <hr style={{color:"blue"}}/>
   <p>The second we find a good fit, you'll be called in for the Zoom interview.</p>
</div>
</div>
<br />
<div className='rooww'>
<div className='col1aa'>
  <img src={logca5} alt="" />
</div>
<div className='col2aa'>
   <h2>Zoom Interview</h2>
   <hr style={{color:"blue"}}/>
   <p>One-on-one session with the Recruitment Team Lead, we'll let you know within 48 hours.</p>
</div>
<br />
</div>
<br/>
<div className='rooww'>
<div className='col1aa'>
  <img src={logca3} alt="" />
</div>

<div className='col2aa'>
   <h2>Golden Ticket</h2>
   <hr style={{color:"rgb(5, 22, 40)"}}/>
   <p>Congratulations, you're a great fit, our HR Associate will reach out the offer letter!</p>
</div>
</div>
<br/>
<div className='rooww'>
<div className='col1aa'>
  <img src={logca6} alt="" />
</div>
<div className='col2aa'>
   <h2>Pre-Onboarding Session</h2>
   <hr style={{color:"blue"}}/>
   <p>Let's get to know each other, what do you do for fun?</p>
</div>
</div>
<br />
<div className='rooww'>
<div className='col1aa'>
  <img src={logca4} alt="" />
</div>
<div className='col2aa'>
   <h2>Welcome to HiringGate!</h2>
   <hr style={{color:"blue"}}/>
   <p>Your orientation session will plug you into our engaging community and next steps.</p>
</div>
</div>
</div>
</section>
   
   <section className=''> 
    <div className='career_mid'>
      
<br /><br />
<div className='car_row'>
<div className='car_col1'>

</div>

<div className='car_col2'>
<h2>All Jobs</h2>
<br />
<div className='caree_card'>
<div className='card_row'>
 <div className='card_col1'>
    <img src={lg1} alt="log" />
 </div>
 <div className='card_col2'>
    <h2>Social Media Assistant</h2>
    <p>Nomad . Paris, France</p>

 </div>
 <div className='card_col3'>
    <button>Apply</button>
 </div>
</div>
</div>
<br />
<div className='caree_card'>
<div className='card_row'>
 <div className='card_col1'>
    <img src={lg1} alt="log" />
 </div>
 <div className='card_col2'>
    <h2>Social Media Assistant</h2>
    <p>Nomad . Paris, France</p>

 </div>
 <div className='card_col3'>
    <button>Apply</button>
 </div>
</div>
</div>
<br />
<div className='caree_card'>
<div className='card_row'>
 <div className='card_col1'>
    <img src={lg1} alt="log" />
 </div>
 <div className='card_col2'>
    <h2>Social Media Assistant</h2>
    <p>Nomad . Paris, France</p>

 </div>
 <div className='card_col3'>
    <button>Apply</button>
 </div>
</div>
</div>
<br />
<div className='caree_card'>
<div className='card_row'>
 <div className='card_col1'>
    <img src={lg1} alt="log" />
 </div>
 <div className='card_col2'>
    <h2>Social Media Assistant</h2>
    <p>Nomad . Paris, France</p>

 </div>
 <div className='card_col3'>
    <button>Apply</button>
 </div>
</div>
</div>
<br />
<div className='caree_card'>
<div className='card_row'>
 <div className='card_col1'>
    <img src={lg1} alt="log" />
 </div>
 <div className='card_col2'>
    <h2>Social Media Assistant</h2>
    <p>Nomad . Paris, France</p>

 </div>
 <div className='card_col3'>
    <button>Apply</button>
 </div>
</div>
</div>
</div>
</div>
    
    </div>
   </section>

    </div>
  )
}
export default Careers;