import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import hpic from '../images/hpic1-min.png'
import hpic2 from '../images/hpic2.png'
import '../components/Styles/home.css'
import '../components/Styles/about.css'
import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';


 const About =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>WealthPath Conference 2024: Empowering Your Financial Future</title>
      <meta name="description" content="Empower your financial journey at WealthPath Conference 2024. Gain insights into investing, wealth management, and financial planning from leading experts."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
      <section  className='header_top'>
      <div className='headertp1'  >
      <br /><br />
        <div className='h_row'>
         <div className='h_col'>
        <h1>About Us</h1>
<br />
  <img src={hpic} alt="paint" />
  <br /><br />
     <h2>Connecting Top IT Talent with Leading Companies. Transforming Remote Hiring, One Match at a Time. </h2>
   <br />
         </div>
         <div className='h_col2a'>
         <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>

      <section className='about__us_mid'>
       <div className='ab'>
       <h2>Our Story</h2>
           <p>Hiring Gate was founded with a vision to revolutionize the remote hiring process for IT professionals. Our platform bridges the gap between talented <br /> candidates and forward-thinking companies, ensuring a seamless and efficient recruitment experience. </p>

       </div>
      </section>
      <br /><br />
<section className=''>
<div className='ab__mid'>
<h2>Our Values  </h2>

<br />
<div className='ab__row'>
<div className='ab__col'>
<h2>Integrity </h2>
<p>We uphold the highest standards of integrity in all our interactions, ensuring trust and transparency for both companies and candidates. </p>

</div>
<div className='ab__col'>
<h2>Innovation </h2>
  <p>At Hiring Gate, we continuously innovate to enhance our services, using the latest technology to streamline the hiring process and deliver exceptional results. 

</p>

</div>

</div>
<br />
<div className='ab__row'>
<div className='ab__col'>
<h2 style={{textAlign:"right"}}>Excellence </h2>
  <p style={{textAlign:"right"}}>We strive for excellence in everything we do, from candidate screening to client support, ensuring top-quality matches and satisfaction for all parties involved. 

</p>
  
</div>
<div className='ab__col'>
<h2>Inclusivity</h2>
  <p>Our commitment to inclusivity means we embrace diversity and create equal opportunities for all candidates, fostering a culture of respect and collaboration.
</p>

</div>

</div>
</div>
</section>
      <br /><br />
      <section className='sec__last_1'>
        
  <div className='sec__last1m'>
  <h2>Call to Action</h2>
  <p>Ready to experience seamless remote hiring? </p>
  <button><a href="contact">Join Now</a> </button>
  </div>
 </section>
<br /><br />
 <section className=''>
  <div className='lst_sec'>
   <h2>What Our Clients Say…</h2>
   <br /><br />
   <div className='lst_row'>
    
    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>
   </div>


  </div>
 </section>

    </div>
  )
}
export default About;